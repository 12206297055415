<template>
  <div>
    <div class="container-fluid">
      <b-card class="main-card">
        <b-row style="margin-right: 0px; margin-left: 0px">
          <b-col
            cols="4"
            lg="6"
            style="
              border-right: 2px solid rgba(122, 122, 122, 0.1);
              position: relative;
            "
          >
            <h2 style="font-weight: 500; margin-bottom: 0px">{{ wcName }}</h2>
          </b-col>
          <b-col cols="8" lg="6">
            <portal to="GroupParameter" :disabled="true">
              <date-filter style="float: right" :tabIndex="tabIndex" />
            </portal>
            <b-button
              variant="info"
              :class="insightVisible ? null : 'collapsed'"
              :aria-expanded="insightVisible ? 'true' : 'false'"
              aria-controls="insight"
              @click="insightVisible = !insightVisible"
              style="margin-right: 20px"
            >
              {{ $t("Insights") }}
            </b-button>
          </b-col>
        </b-row>
        <div>
          <b-collapse id="insight" v-model="insightVisible" class="mt-2">
            <b-card>
              <ul style="list-style: none; padding: 0; margin: 0">
                <li
                  v-for="(item, index) in insightData"
                  :key="index"
                  :style="{
                    color: item.color,
                    fontSize: '1.1em',
                    fontWeight: 'bold',
                    marginBottom:
                      index !== insightData.length - 1 ? '5px' : '0',
                  }"
                >
                  <span :style="{ color: item.color, marginRight: '5px' }"
                    >&#x26A0;</span
                  >
                  {{ item.message }}
                </li>
              </ul>
            </b-card>
          </b-collapse>
        </div>
        <b-row v-if="isFilterProvided">
          <b-col>
            <b-tabs v-model="tabIndex">
              <b-tab lazy :title="$t('Info')">
                <SectionInfo />
              </b-tab>
              <b-tab
                v-if="tabStatus.showParamaterTab"
                lazy
                :title="$t('Parameters')"
              >
                <SectionParameters />
              </b-tab>
              <b-tab
                v-if="tabStatus.showDowntimeTab"
                lazy
                :title="$t('Downtimes')"
              >
                <SectionDowntimes />
              </b-tab>
            </b-tabs>
            <!--<SectionWorkcenter />-->
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
//dispatch country metrics data.
import { useRouter } from "@/@core/utils/utils";
import {
  BCard,
  BRow,
  BCol,
  BTab,
  BTabs,
  BCollapse,
  BButton,
  BCardText,
} from "bootstrap-vue";
import SectionDowntimes from "./SectionDowntimes.vue";
import SectionInfo from "./SectionInfo.vue";
import SectionParameters from "./SectionParameters.vue";
import {
  computed,
  onUnmounted,
  ref,
  provide,
  onBeforeUnmount,
  onMounted,
} from "@vue/composition-api";
import workcenterDataStoreModule from "../store/workcenterStoreModule";
import store from "@/store";
import DateFilter from "../components/DateFilter.vue";
import useExtensionalBreadCrumb from "@/@core/layouts/components/app-bread-crumb/useExtensionalBreadCrumb";

export default {
  components: {
    SectionDowntimes,
    SectionInfo,
    SectionParameters,
    BCard,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCollapse,
    BButton,
    DateFilter,
  },
  // directives: {
  //   'b-toggle': VBToggle,
  //   Ripple,
  // },
  setup() {
    const router = useRouter();
    const routerParams = router.route.value.params;
    const PAMIS_WORKCENTER_MODULE = "pamis-workcenter-module";
    const wcName = routerParams.name;
    const wcId = router.route.value.params.id;
    const tabIndex = ref(0);

    if (!store.hasModule(PAMIS_WORKCENTER_MODULE)) {
      store.registerModule(PAMIS_WORKCENTER_MODULE, workcenterDataStoreModule);
    }

    onMounted(() => {
      store.dispatch(
        "pamis-workcenter-module/SetWorkcenterId",
        Number(routerParams.id)
      );
      store.dispatch("pamis-workcenter-module/GetDateFilters");
      store.dispatch("pamis-workcenter-module/GetDateFiltersWithDates", {
        workcenterId: wcId,
      });
    });

    const breadCrumbObject = computed(() => {
      const parentTree = store.getters["pamis-workcenter-module/GetParentTree"];
      if (parentTree.length == 0) return [];

      const breadCrumb = parentTree.map((x, i = 0) => {
        //to control whether its country route or workcenter route
        i++;
        if (i != 2) {
          return {
            text: x.text,
            to:
              i == 1
                ? `/country/${x.text}/id=${x.id}/tab=0`
                : `/workcenter/${x.text}/id=${x.id}`,
            active: false,
          };
        } else {
          // for plant
          return {
            text: x.text,
            active: true,
          };
        }
      });
      breadCrumb[breadCrumb.length - 1].active = true;
      return breadCrumb;
    });
    useExtensionalBreadCrumb(breadCrumbObject);

    onBeforeUnmount(() => {
      store.dispatch("pamis-workcenter-module/RESET_STATE");
    });
    onUnmounted(() => {
      if (store.hasModule(PAMIS_WORKCENTER_MODULE)) {
        store.unregisterModule(PAMIS_WORKCENTER_MODULE);
      }
      store.dispatch("app/CLEAR_BREAD_CRUMB");
    });
    const tabStatus = computed(() => {
      return store.getters["pamis-workcenter-module/GetWorkcenterTabStatus"];
    });

    provide("workcenter", { name: wcName, id: wcId });

    const isFilterProvided = computed(() => {
      const dateFilter =
        store.getters["pamis-workcenter-module/GetDateFiltersWithDates"];
      if (dateFilter == null) return false;
      if (dateFilter.length > 0) {
        return true;
      }
      return false;
    });

    const insightData = computed(() => {
      return (
        store.getters["pamis-workcenter-module/GetWorkcenterInsight"]
          ?.datasource ?? null
      );
    });

    return {
      isFilterProvided,
      tabStatus,
      wcName,
      tabIndex,
      insightVisible: false,
      insightData,
    };
  },
};
</script>

<style scoped>
.button-settings,
button {
  display: flex;
  float: right;
  padding: 5px, 5px, 5px, 5px !important;
  margin: 0px;
}

.main-card {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
}

.leave-active,
.enter-active {
  transition: all 0.3s;
}

.enter-from {
  opacity: 0;
}

.leave-to {
  opacity: 0;
}
</style>
