<template>
  <div>
    <slot> </slot>
    <b-row v-if="checkGroupStatus === 'success'">
      <b-col lg="3" cols="6" v-for="data in [...wcWidgetsData.data, ...wcTargetWidgetsData.data]"
        :key="JSON.stringify(data)">
        <Widget :widgetData="data" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Widget from "../widgets/Widget.vue";
import { BRow, BCol } from "bootstrap-vue";
import useGroupWidgetWorkcenter from "@/views/pamis/workcenter/components/composables/GroupWidgetWorkcenter";
import { watch } from "@vue/composition-api";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    Widget,

  },
  emits: ["hasData"],
  setup(props, { emit }) {
    const { wcWidgetsData, wcTargetWidgetsData, checkGroupStatus } =
      useGroupWidgetWorkcenter();
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });

    return {
      wcTargetWidgetsData,
      wcWidgetsData,
      checkGroupStatus,
    };
  },
};
//there will be multiple instances of normal bars
</script>
