<template>
  <div>
    <b-card no-body style="padding-top: clamp(15px, 2vw, 25px)">
      <b-row
        style="
          margin-left: Clamp(0px, 0.5vw, 20px);
          margin-bottom: Clamp(4px, 00.5vw, 15px);
        "
      >
        <b-col>
          <b-img fluid src="@/assets/images/pamis/device_cc300.jpg"> </b-img
        ></b-col>
        <b-col>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Machine Name: </b>
            <b-col cols="7" class="right-prop-column">PT15</b-col>
          </p>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Machine Type: </b>
            <b-col cols="7" class="right-prop-column">CC_300</b-col>
          </p>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Mold Code: </b>
            <b-col cols="7" class="right-prop-column">C000007307</b-col>
          </p>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Status: </b>
            <b-col cols="7" class="right-prop-column">Working</b-col>
          </p>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Product Code: </b>
            <b-col cols="7" class="right-prop-column">0285512</b-col>
          </p>
          <p class="device-props row">
            <b class="col-5 left-prop-column">Spec Code: </b>
            <b-col cols="7" class="right-prop-column">SP5200K8</b-col>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><h5
            class="footer-header"
            style="text-align: center; font-weight: 700"
          >
            Shift
          </h5>
          <h5
            class="footer-header"
            style="text-align: center; font-weight: 700"
          >
            A
          </h5>
        </b-col>
      </b-row>
      <b-card-footer style="padding: 12px 21px 12px 21px">
        <b-row>
          <b-col>
            <h5 class="text-muted m-b-10 footer-header">
              {{ data.leftHeader }}
            </h5>
            <h4
              class="m-b-0 f-w-600 footer-value"
              :id="`${data.leftHeader}_value`"
            >
              {{ data.leftValue }}
            </h4>
          </b-col>
          <b-col class="col-6" style="border-left: 1px solid #ebedf3">
            <h5 class="text-muted m-b-10 footer-header">
              {{ data.rightHeader }}
            </h5>
            <h4
              class="m-b-0 f-w-600 footer-value"
              :id="`${data.rightHeader}_value`"
            >
              {{ data.rightValue }}
            </h4>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardFooter, BRow, BCol, BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
    BCard,
    BCardFooter,
    BRow,
    BCol,
  },
  props: {
    data: {
      name: null,
      subName: null,
      leftHeader: null,
      rightHeader: null,
      leftValue: null,
      rightValue: null,
      logo: null,
    },
  },
  computed: {
    getCardColor() {
      if (this.data.rightValue < 50) {
        return "red";
      } else if (this.data.rightValue <= this.data.efficiencyLimit) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
};
</script>
<style>
.device-props {
  margin-bottom: clamp(-5px, -1vh, -8px);
  font-size: Clamp(0.5em, 1.6vw, 0.9em);
  color: black;
}
.footer-header {
  text-align: center;
  font-size: Clamp(0.7em, 1.8vw, 1em);
}
.footer-value {
  text-align: center;
  font-size: Clamp(0.7em, 1.8vw, 1.2em);
}
.left-prop-column {
  padding-right: 0px;
  padding-left: 0px;
}
.right-prop-column {
  padding-right: 0px;
  padding-left: 0px;
}
</style>
