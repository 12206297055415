<template>
  <div>
    <slot></slot>
    <b-row v-if="checkGroupStatus ==='success'">
      
      <b-col cols="12" lg="" md="">
        <Widget
          :widgetData="machineProperties"
          :key="JSON.stringify(machineProperties)"
        />
      </b-col>
      <b-col
        cols="12"
        lg="7"
        md="6"
        v-show="datefilterId == 2 || datefilterId == 10"
      >
        <Widget
          :widgetData="workcenterShiftsOptions"
          :key="JSON.stringify(workcenterShiftsOptions)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import useGroupProduct from "@/views/pamis/workcenter/components/composables/GroupProduct";
import { BRow, BCol, BFormSelect } from "bootstrap-vue";
import Widget from "../widgets/Widget.vue";
import { ref, inject, computed, watch } from "@vue/composition-api";
import store from "@/store";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormSelect,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  emits:["hasData"],
  setup(props,{ emit }) {
    const datefilterId = computed(() => {
      return store.getters["pamis-workcenter-module/GetDateFilterId"];
    });
    const { machineProperties, workcenterShiftsOptions, checkGroupStatus } =
      useGroupProduct();
      emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });
    return {
      machineProperties,
      datefilterId,
      workcenterShiftsOptions,
      checkGroupStatus,
    };
  },
};
</script>
<style></style>
