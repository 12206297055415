import ApiService from "@/services/pamisDashboardApi.service"
import netproApiService from "@/services/netproApi.service"
import parameterApiService from "@/services/parameterApi.service"
import mockdatagetworkcenterdata from "@/mockdata/getworkcenterdata.json"
/*
import parameterstab from "@/mockdata/parameterstab.json"
import mockdataworkcentergetdatefilters from "@/mockdata/workcentergetdatefilters.json"
import mockdatagetdatefilterswithdates from "@/mockdata/getdatefilterswithdates.json"
import getbreakdowndata from "@/mockdata/getbreakdowndata.json"
*/
import mock_data from "./mock_data.json"
import downtimes_mock_data from "./downtimes_mock_data.json"
// SONRADAN INCELENMEK ISTER
const useMockData = true
const getDefaultState = () => {
  return {
    workcenterSummaryInfo: null,
    workcenterProductionData: null,
    workcenterEfficiencyTrend: null,
    shiftsProductionQuantity: null,
    workcenterId: null,
    mesBreakdownInfo: null,
    breakdownActualInfo: null,
    parametersInfo: null,
    selectedParameterInfo: null,
    wcStatus: null,
    trendsOfParameter: null,
    alarmsInfo: null,
    topDefects: null,
    breakdownRate: null,
    workcenterChild: null,
    workcenterInsight: null,
    workcenterDownTimeChart: null,
    workcenterDownTimePieChart: null,
    wcTabStatus: {
      showParamaterTab: false,
      showDowntimeTab: false,
    },
  }
}
const CancelAllRequests = () => {
  ApiService.abortRequests()
  netproApiService.abortRequests()
  parameterApiService.abortRequests()
}
const OnDateFilterChangeResetStates = state => {
  state.workcenterSummaryInfo = null
  state.workcenterProductionData = null
  state.workcenterEfficiencyTrend = null
  state.shiftsProductionQuantity = null
  state.mesBreakdownInfo = null
  state.breakdownActualInfo = null
  state.wcStatus = null
  state.trendsOfParameter = null
  state.alarmsInfo = null
  state.topDefects = null
  state.breakdownRate = null
  state.workcenterChild = null
  state.workcenterDownTimeChart = null
  state.workcenterDownTimePieChart = null
  state.workcenterInsight = null
}
export default {
  namespaced: true,
  state: {
    parentTree: [],
    dateFilters: null,
    dateFiltersWithDates: null,
    dateFilterId: 2,
    ...getDefaultState(),
  },
  getters: {
    GetParentTree(state) {
      return state.parentTree
    },
    GetWorkcenterTabStatus(state) {
      return state.wcTabStatus
    },
    GetWorkcenterChildInfo(state) {
      return state.workcenterChild
    },
    GetWcStatus(state) {
      return state.wcStatus
    },
    GetMESBreakdownInfo(state) {
      return state.mesBreakdownInfo
    },
    GetBreakdownActualInfo(state) {
      return state.breakdownActualInfo
    },
    GetWorkcenterSummaryInfo(state) {
      return state.workcenterSummaryInfo
    },
    GetSelectedParameter(state) {
      return state.selectedParameterInfo
    },
    GetWorkcenterId(state) {
      return state.workcenterId
    },
    GetDateFilters(state) {
      return state.dateFilters
    },
    GetDateFilterId(state) {
      return state.dateFilterId
    },
    GetDateFiltersWithDates(state) {
      return state.dateFiltersWithDates
    },
    GetWorkcenterProductionData(state) {
      return state.workcenterProductionData
    },
    GetWorkcenterEfficiencyTrend(state) {
      return state.workcenterEfficiencyTrend
    },
    GetShiftsProductionQuantity(state) {
      return state.shiftsProductionQuantity
    },
    GetParametersInfo(state) {
      return state.parametersInfo
    },
    GetAlarmsInfo(state) {
      return state.alarmsInfo
    },
    GetTrendsOfParameter(state) {
      return state.trendsOfParameter
    },
    GetTopDefects(state) {
      return state.topDefects
    },
    GetTopDefectCount(state) {
      return state.topDefectCount
    },
    GetBreakdownRate(state) {
      return state.breakdownRate
    },
    GetWorkcenterDowntimeChart(state) {
      return state.workcenterDownTimeChart
    },
    GetWorkcenterDowntimePieChart(state) {
      return state.workcenterDownTimePieChart
    },
    GetWorkcenterInsight(state) {
      return state.workcenterInsight
    }
  },
  actions: {
    GetBreakdownRate(ctx, param) {
      /*
      let data = getbreakdowndata;
      ctx.commit("SetBreakdownRate", {
        datasource: data.breakdownrate,
        context: "Breakdown Rate",
      })
        */
      return new Promise((resolve, reject) => {
        ApiService.query("Breakdown/Workcenter/GetBreakdownRate", {
          workcenterId: param.workcenterId,
          dateFilterId: param.dateFilterId,
        })
          .then(response => {
            ctx.commit("SetBreakdownRate", {
              datasource: response.data,
              context: "Breakdown Rate",
            })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetBreakdownRate", { isError: true, context: error })
            reject(error)
          })
      })
    },
    GetTopDefects(ctx, param) {
      return new Promise((resolve, reject) => {
        ApiService.query("ProductionData/Workcenter/TopDefects", {
          workcenterId: param.workcenterId,
          defectCount: param.defectCount,
          dateFilterId: param.dateFilterId,
        })
          .then(response => {
            ctx.commit("SetTopDefects", {
              defectCount: response.config.params.defectCount,
              ...response.data,
              context: "Top Defects chart",
            })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetTopDefects", { isError: true, context: error })
            reject(error)
          })
      })
    },
    GetMESBreakdownInfo(ctx, param) {
      if (useMockData) {
        return new Promise(resolve => {

          let data = downtimes_mock_data

          data = data.map(item => ({
            start: item.startDate,
            end: item.endDate,
            duration: item.duration,
            description: item.description,
          }))
          ctx.commit("SetMESBreakdownInfo", {
            ...{ datasource: data },
            context: "MES Breakdown Info",
          })
          resolve()
        })
      } else {
        return new Promise((resolve, reject) => {
          netproApiService
            .query("NetPro/GetNetproLineStatus", {
              MachineName: param.machineName,
              startDate: param.startDate,
              endDate: param.endDate,
            })
            .then(response => {
              let data = {}
              data.datasource = response.data.map(item => ({
                start: item.startDate,
                end: item.endDate,
                duration: item.duration,
                fault: item.description,
              }))
              ctx.commit("SetMESBreakdownInfo", {
                ...data,
                context: "MES Breakdown Info",
              })
              resolve(response.data)
            })
            .catch(error => {
              ctx.commit("SetMESBreakdownInfo", {
                isError: true,
                context: error,
              })
              reject(error)
            })
        })
      }
    },
    GetBreakdownActualInfo(ctx, param) {
      // let data = getbreakdowndata;
      // let datasrc = data.breakdowndata.map(item => ({
      //   start: item.startDate,
      //   end: item.endDate,
      //   duration: item.duration,
      //   fault: item.breakdownName,
      // }))
      // ctx.commit("SetBreakdownActualInfo", {
      //   datasource: datasrc,
      //   context: "Actual Breakdown table",
      // })
      return new Promise((resolve, reject) => {
        ApiService.query("Breakdown/WorkCenter/GetBreakdownData", {
          workcenterId: param.workcenterId,
          dateFilterId: param.dateFilterId,
        })
          .then(response => {
            let data = response.data.map(item => ({
              start: item.startDate,
              end: item.endDate,
              duration: item.duration,
              fault: item.breakdownName,
            }))
            ctx.commit("SetBreakdownActualInfo", {
              datasource: data,
              context: "Actual Breakdown table",
            })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetBreakdownActualInfo", {
              isError: true,
              context: error,
            })
            reject(error)
          })
      })
    },
    GetDateFilters(ctx) {
      return new Promise((resolve, reject) => {
        ApiService.get("Utility/Workcenter", "GetDateFilters")
          .then(response => {
            ctx.commit("SetDateFilters", response.data)
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetDateFilters", { isError: true, context: error })
            reject(error)
          })
      })
    },
    GetDateFiltersWithDates(ctx, param) {
      return new Promise((resolve, reject) => {
        ApiService.query("Utility/Workcenter/GetDateFiltersWithDates", param)
          .then(response => {
            ctx.commit("SetDateFiltersWithDates", response.data)
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetDateFiltersWithDates", {
              isError: true,
              context: error,
            })
            reject(error)
          })
      })
    },
    SetWorkcenterId(ctx, workcenterId) {
      ctx.commit("SetWorkcenterId", workcenterId)
    },
    SetDateFilterId(ctx, newId) {
      ctx.commit("SetDateFilterId", newId)
    },
    SetSelectedParameter(ctx, parameterObj) {
      ctx.commit("SetSelectedParameter", parameterObj)
    },
    GetParametersInfo(ctx, param) {
      return new Promise((resolve, reject) => {
        parameterApiService
          .query(`Parameter/ParametersByWorkCenterId`, {
            WorkCenterId: param.workcenterId,
          })
          .then(response => {
            let data = {}
            data.datasource = response.data.map(item => ({
              id: item.parameterId,
              tag: item.name,
              date: item.createdDate,
              delta: item.delta == null ? "-" : Number(item.delta),
              nom: Number(item.nominal),
              min: Number(item.min),
              max: Number(item.max),
              current: Number(item.value),
            }))
            ctx.commit("SetParametersInfo", {
              ...data,
              context: "Parameter Info",
            })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetParametersInfo", {
              isError: true,
              context: error.message,
            })
            reject(error)
          })
      })
    },
    GetAlarmsInfo(ctx, param) {
      // let data = {}
      // data.datasource = []
      // const min_value = Math.random() * (350 - 270) + 270;
      // const max_value = Math.random() * (420 - 360) + 360;

      // const numItems = Math.floor(Math.random() * (7 - 2 + 1)) + 2;
      // const alarmData = [];

      // for (let i = 0; i < numItems; i++) {
      //   const randomTime = new Date(Date.now() - Math.random() * 24 * 60 * 60 * 1000);
      //   const exceedValue = Math.random() > 0.5
      //     ? (Math.random() * (min_value - (min_value - 20)) + (min_value - 20)).toFixed(1)
      //     : (Math.random() * ((max_value + 20) - max_value) + max_value).toFixed(1);

      //   data.datasource.push({
      //     date: randomTime.toISOString(),
      //     min: min_value.toString(),
      //     max: max_value.toString(),
      //     exceed: exceedValue.toString(),
      //     tag: param.ParameterName
      //   });
      // }
      // ctx.commit("SetAlarmsInfo", { ...data, context: "Alarms Info" })
      return new Promise((resolve, reject) => {
        parameterApiService
          .query(`Parameter/AlarmsOfParameter`, {
            ParameterId: param.parameterId,
            StartDate: param.startDate,
            EndDate: param.endDate,
          })
          .then(response => {
            let data = {}
            data.datasource = response.data.map(item => ({
              tag: item.name,
              date: item.createdDate,
              min: item.min,
              max: item.max,
              exceed: item.exceed,
            }))
            ctx.commit("SetAlarmsInfo", { ...data, context: "Alarms Info" })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetAlarmsInfo", {
              isError: true,
              context: error,
            })
            reject(error)
          })
      })
    },
    GetTrendsOfParameter(ctx, param) {
      // let mockdata = JSON.parse(JSON.stringify(parameterstab.trendsofparameter));
      // mockdata.datasource = [];
      // mockdata.name = param.ParameterName;
      // const startDate = new Date();
      // startDate.setHours(startDate.getHours() - 24);

      // let currentValue = Math.random() * 40 - 20
      // let trend = (Math.random() - 0.5) * 0.5;

      // for (let i = 0; i < 288; i++) {
      //   const date = new Date(startDate);
      //   date.setMinutes(date.getMinutes() + i * 5);

      //   trend += (Math.random() - 0.5) * 0.5;
      //   currentValue += (Math.random() * 2 - 1) * trend;

      //   currentValue = Math.max(Math.min(currentValue, 20), -20);

      //   mockdata.datasource.push({
      //     v: currentValue.toFixed(1),
      //     d: date.toISOString()
      //   });
      // }

      // ctx.commit("SetTrendsOfParameter", {
      //   ...mockdata,
      //   context: "Trends of parameter",
      // })
      return new Promise((resolve, reject) => {
        parameterApiService
          .query(`Parameter/TrendsOfParameter`, {
            ParameterId: param.ParameterId,
            StartDate: param.startDate,
            EndDate: param.endDate,
          })
          .then(response => {
            ctx.commit("SetTrendsOfParameter", {
              ...response.data,
              context: "Trends of parameter",
            })
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetTrendsOfParameter", {
              isError: true,
              context: error,
            })
            reject(error)
          })
      })
    },
    GetWorkcenterDowntimeChart(ctx) {
      return new Promise(resolve => {
        const data = {
          datasource: [
            {
              name: "Mold Piston Problem",
              workcenter: "APL",
              startDate: Date.now() - 200000,
              endDate: Date.now(),
            },
            {
              name: "Mold Piston Problem",
              workcenter: "IML",
              startDate: Date.now() - 80000,
              endDate: Date.now(),
            },
            {
              name: "Pusher Doesn't Move/Broken",
              workcenter: "IML",
              startDate: Date.now() - 200000,
              endDate: Date.now(),
            },
            {
              name: "Hi Doesn't Move/Broken",
              workcenter: "COL",
              startDate: Date.now() - 100000,
              endDate: Date.now() - 50000,
            },
            {
              name: "Doesn't Move/Broken",
              workcenter: "COL",
              startDate: Date.now() - 500000,
              endDate: Date.now(),
            },

          ],
        }
        ctx.commit("SetWorkcenterDowntimeChart", data)
        resolve(data)
      })
    },

    GetWorkcenterData(ctx, param) {
      const insightData = mockdatagetworkcenterdata.data.insight
      return new Promise((resolve, reject) => {
        ApiService.query("ProductionData/Workcenter/GetWorkCenterData", {
          workcenterId: param.workcenterId,
          dateFilterId: param.dateFilterId,
        })
          .then(response => {
            const { data } = response
            ctx.commit("SetWorkcenterSummaryInfo", {
              datasource: data.summaryInfo,
            })
            ctx.commit("SetWorkcenterProductionData", {
              datasource: data.productionQuantity,
              kpiProductionQuantity: data.kpiProductionQuantity,
              kpiPPM: data.kpiPPM,
            })
            ctx.commit("SetShiftsProductionQuantity", {
              datasource: data.shiftsProductionQuantity,
            })
            ctx.commit("SetWorkcenterEfficiencyTrend", {
              datasource: data.efficiencyTrend,
              kpiEfficiency: data.kpiEfficiency,
            })
            ctx.commit("SetWcStatus", { datasource: data.status })
            ctx.commit("SetWorkcenterChildInfo", {
              datasource: data.childsInfo,
            })

            if (data.topDefects?.length == 0) ctx.commit("SetTopDefects", { datasource: data.breakdowns, context: 'top defects chart' })
            else
              ctx.commit("SetTopDefects", { datasource: data.topDefects })

            ctx.commit("SetWorkcenterTabStatus", {
              showParamaterTab: data.showParamaterTab,
              showDowntimeTab: data.showDowntimeTab,
            })
            if (data.breakdowns?.length == 0) {
              ctx.commit("SetWorkcenterDowntimeChart", {
                datasource: data.breakdowns,
                context: 'downtimes timeline chart',
              })
            }
            else {
              ctx.commit("SetWorkcenterDowntimeChart", {
                datasource: data.breakdowns,
                startDateByFilter: data.startDateByFilter,
                endDateByFilter: data.startDateByFilter
              })
            }
            if (data.breakdowns?.length == 0) {
              ctx.commit("SetWorkcenterDowntimePieChart", { datasource: data.breakdowns, context: 'downtimes pie chart' })
            }
            else {
              ctx.commit("SetWorkcenterDowntimePieChart", {
                datasource: data.breakdowns,
                startDateByFilter: data.startDateByFilter,
                endDateByFilter: data.startDateByFilter
              })
            }
            ctx.commit("SetWorkcenterInsight", { datasource: insightData })


            ctx.commit("SetParentTree", data.parentTree)
            resolve(response.data)
          })
          .catch(error => {
            ctx.commit("SetWorkcenterSummaryInfo", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterProductionData", {
              isError: true,
              context: error,
            })
            ctx.commit("SetShiftsProductionQuantity", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterEfficiencyTrend", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWcStatus", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterChildInfo", {
              isError: true,
              context: error,
            })

            ctx.commit("SetWorkcenterInsight", {

              isError: true,
              context: error,
            })
            ctx.commit("SetTopDefects", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterTabStatus", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterDowntimePieChart", {
              isError: true,
              context: error,
            })
            ctx.commit("SetWorkcenterDowntimeChart", {
              isError: true,
              context: error,
            })
            reject(error)
          })
      })
    },

    RESET_STATE(ctx) {
      ctx.commit("RESET_STATE")
      ApiService.abortRequests()
    },
    RESET_PARAMETER_DATA(ctx) {
      ctx.commit("RESET_PARAMETER_DATA")
    },
  },
  mutations: {
    RESET_PARAMETER_DATA(state) {
      Object.assign(state, { trendsOfParameter: null, alarmsInfo: null })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SetWorkcenterDowntimePieChart(state, param) {
      state.workcenterDownTimePieChart = param
    },
    SetWorkcenterDowntimeChart(state, param) {
      state.workcenterDownTimeChart = param
    },
    SetParentTree(state, param) {
      state.parentTree = param
    },
    SetWorkcenterTabStatus(state, tabStatus) {
      state.wcTabStatus = tabStatus
    },
    SetTopDefects(state, topDefects) {
      state.topDefects = topDefects
    },
    SetTrendsOfParameter(state, trendsOfParameter) {
      state.trendsOfParameter = trendsOfParameter
    },
    SetWcStatus(state, wcStatus) {
      state.wcStatus = wcStatus
    },
    SetMESBreakdownInfo(state, breakdownInfo) {
      state.mesBreakdownInfo = breakdownInfo
    },
    SetBreakdownActualInfo(state, breakdownActualInfo) {
      state.breakdownActualInfo = breakdownActualInfo
    },
    SetWorkcenterSummaryInfo(state, summaryInfo) {
      state.workcenterSummaryInfo = summaryInfo
    },
    SetSelectedParameter(state, parameterObj) {
      state.selectedParameterInfo = parameterObj
    },
    SetDateFilters(state, filters) {
      state.dateFilters = filters
    },
    SetDateFiltersWithDates(state, filters) {
      state.dateFiltersWithDates = filters
    },
    SetDateFilterId(state, newDateFilterId) {
      OnDateFilterChangeResetStates(state)
      CancelAllRequests()
      state.dateFilterId = newDateFilterId
    },
    SetWorkcenterId(state, workcenterId) {
      state.workcenterId = workcenterId
    },
    SetWorkcenterProductionData(state, productionQuantity) {
      state.workcenterProductionData = productionQuantity
    },
    SetWorkcenterEfficiencyTrend(state, efficiencyTrend) {
      state.workcenterEfficiencyTrend = efficiencyTrend
    },
    SetShiftsProductionQuantity(state, shiftsProductionQuantity) {
      state.shiftsProductionQuantity = shiftsProductionQuantity
    },
    SetAlarmsInfo(state, alarmsInfo) {
      state.alarmsInfo = alarmsInfo
    },
    SetParametersInfo(state, parametersInfo) {
      state.parametersInfo = parametersInfo
    },
    SetBreakdownRate(state, breakdownRate) {
      state.breakdownRate = breakdownRate
    },
    SetWorkcenterChildInfo(state, data) {
      state.workcenterChild = data
    },
    SetWorkcenterInsight(state, data) {
      state.workcenterInsight = data
    },
  },
}
