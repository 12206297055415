<template>
  <div id="controlchart">
    <b-card>
      <apexchart
        type="rangeBar"
        height="450"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </b-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { BCard } from "bootstrap-vue";
import i18n from '@/libs/i18n'
export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
  },
  props: {
    data: Object,
  },
  created() {},
  data() {
    return {
      series: [],
      chartOptions: {
        colors: [
          "#F44336",
          "#2196F3",
          "#4CAF50",
          "#FFEB3B",
          "#9C27B0",
          "#795548",
          "#E91E63",
          "#03A9F4",
          "#8BC34A",
          "#FFC107",
          "#673AB7",
          "#9E9E9E",
          "#FF5722",
          "#00BCD4",
          "#CDDC39",
          "#FF9800",
          "#3F51B5",
          "#607D8B",
          "#C62828",
          "#1E88E5",
          "#43A047",
          "#FDD835",
          "#AB47BC",
          "#8D6E63",
          "#EC407A",
          "#29B6F6",
          "#9CCC65",
          "#FFCA28",
          "#7E57C2",
          "#BDBDBD",
        ],
        chart: {
          height: 450,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        title: {
          text:  i18n.t('Breakdown Timeline'),
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "20px",
            fontWeight: 500,
            fontFamily: "Helvetica",
            color: "#263238",
          },
        },
        xaxis: {
          type: "datetime",
        },
        stroke: {
          width: 1,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          markers: {},
        },
        tooltip: {
          x: {
            show: true,
            format: "hh:mm",
            formatter: undefined,
          },
        },
        ...this.data.chartOptions,
      },
      ...this.data,
    };
  },
};
</script>
<style></style>
