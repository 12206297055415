<template>
  <div id="controlchart">
    <b-card>
      <b-row>
        <b-col style="text-align: center">
          <h5 class="text-muted m-b-10 header-text">{{ data.header }}</h5>
          <p class="value-text">
            <span style="margin-right: 10px">Total</span
            ><span>{{ data.value }}</span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <highcharts
            :constructor-type="'chart'"
            style="margin-left: clamp(2px, 2vw, 5px); padding-left: 0px"
            :options="options"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import gradientColors from "@/assets/widget/highchartGradientColorPalettes.json";
import anime from "animejs";
import { ref, toRefs, watch } from "@vue/composition-api";
export default {
  props: {
    data: null,
  },
  components: {
    Highcharts: Chart,
    BCard,
    BRow,
    BCol,
  },
  setup(props) {
    const { data } = toRefs(props);
    const count = ref(0);

    setCount(data.value.value);
    watch(data, (newData) => {
      setCount(newData.value);
    });
    function setCount(val) {
      const obj = { n: count.value };
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 1000,
        easing: "linear",
        update: () => {
          count.value = obj.n;
        },
      });
    }
    return {
      count,
    };
  },
  beforeDestroy() {},
  data() {
    return {
      options: {
        colors: [
          gradientColors.blue,
          gradientColors.green,
          gradientColors.orange,
          gradientColors.purple,
          gradientColors.turquoise,
          gradientColors.yellow,
          gradientColors.olivedrab,
          gradientColors.red,
          gradientColors.olivedrab,
        ],
        credits: {
          enabled: false,
        },
        scrollbar: { enabled: false },
        rangeSelector: {
          enabled: false,
          selected: 4,
          inputEnabled: false,
          buttonTheme: {
            visibility: "hidden",
          },
          allButtonsEnabled: false,
          labelStyle: {
            visibility: "hidden",
          },
        },
        navigator: {
          visible: false,
          enabled: false,
        },
        chart: {
          type: "column",
          zooming: { type: "xy" },
        },
        yAxis: [
          {
            title: "",
            min: 0,
          },
        ],
        legend: {
          enabled: true,
          shadow: false,
        },
        tooltip: {
          shared: true,
        },
        title: {
          text: "",
        },

        xAxis: {
          type: "category",
          categories: ["Brazil", "Turkey", "USA", "China"],
        },
        series: [
          {
            name: "Total Quantity",
            color: "rgba(165,170,255,1)",
            data: [150, 73, 20, 120],
          },
          {
            name: "NOKQuantity",
            color: "rgba(90,86,134,1)",
            data: [140, 90, 40, 10],
          },
        ],
        ...this.data,
      },
    };
  },
};
</script>
<style scoped>
.header-text {
  width: 100%;
  padding: 0;
  font-size: clamp(0.8em, 2vw, 1.1em);
}
.value-text {
  font-size: clamp(1em, 2.2vw, 1.25em);
}
</style>
