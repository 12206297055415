import { computed, ref, onBeforeUnmount, watch } from "@vue/composition-api";
import store from "@/store";
import { formatDate } from "@core/utils/filter";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import { isNumber } from "highcharts";
import i18n from '@/libs/i18n'

export default function GroupParameter() {
  function isInRange(item) {
    if (item.current > item.max || item.current < item.min) {
      return -1;
    } else return 1;
  }
  const alarmList = computed(() => {
    const data = store.getters["pamis-workcenter-module/GetAlarmsInfo"]
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      const { datasource } = data;
      let options = {
        response_status: "success",
        widgetType: "TableAlarms",
        header: i18n.t('Logged Alarms'),
        items: datasource,
        fields: [
          { key: "tag", label: i18n.t('Tag') },
          {
            key: "date",
            label: i18n.t('Date'),
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "min",
            formatter: (value, key, item) => {
              if (!isNaN(value)) return Number.parseFloat(value).toFixed(2);
              return value;
            },
          },
          {
            key: "max",
            formatter: (value, key, item) => {
              if (!isNaN(value)) return Number.parseFloat(value).toFixed(2);
              return value;
            },
          },
          {
            key: "exceed",
            label: i18n.t('Exceed'),
            formatter: (value, key, item) => {
              if (!isNaN(value)) return Number.parseFloat(value).toFixed(2);
              return value;
            },
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });
  const parameterList = computed(() => {
    const data = store.getters["pamis-workcenter-module/GetParametersInfo"]

    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      const { datasource } = data;
      const rows = datasource.sort((a, b) => {
        return isInRange(a) - isInRange(b);
      });
      let options = {
        response_status: "success",
        isClick: true,
        widgetType: "TableParameter",
        header: i18n.t('Parameter Table'),
        items: rows,
        fields: [
          { key: "tag", label: i18n.t('Tag') },
          {
            key: "date",
            label: i18n.t('Date'),
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "min",
            formatter: (value, key, item) => {
              if (!isNaN(value) && value)
                return `${Number.parseFloat(value).toFixed(2)}`;
              return `${value}`;
            },
          },
          {
            key: "nom",
            formatter: (value, key, item) => {
              if (!isNaN(value) && value)
                return `${Number.parseFloat(value).toFixed(2)}`;
              return `${value}`;
            },
          },
          {
            key: "max",
            formatter: (value, key, item) => {
              if (!isNaN(value) && value)
                return `${Number.parseFloat(value).toFixed(2)}`;
              return `${value}`;
            },
          },

          {
            key: "current",
            label: i18n.t('Current'),
            formatter: (value, key, item) => {
              if (!isNaN(value) && value)
                return `${Number.parseFloat(value).toFixed(2)}`;
              return `${value}`;
            },
          },
          {
            key: "delta",
            formatter: (value, key, item) => {
              if (!isNaN(value) && value)
                return `${Number.parseFloat(value).toFixed(2)}`;
              return `${value}`;
            },
          },
        ],
      };
      return options;
    } else {
      return { ...dataState };
    }
  });
  const parameterTrendOptions = computed(() => {
    const parameterData = store.getters["pamis-workcenter-module/GetTrendsOfParameter"];
    const dataState = GetDataStatus(parameterData);
    if (dataState.response_status == "success") {
      const seriesData = parameterData.datasource.map((key) => {
        let date = new Date(key.d);
        return {
          x: date.getTime() - date.getTimezoneOffset() * 60 * 1000,
          y: Number(key.v),
        };
      });

      //find categories
      let options = {
        response_status: "success",
        widgetType: "HighchartLine",
        title: {
          text: parameterData.name,
        },
        xAxis: {
          type: "datetime",
          title: {
            text: i18n.t('Date'),
          },
        },
        yAxis: {
          title: {
            text: i18n.t('Value'),
          },
        },
        plotOptions: {
          series: {
            lineWidth: 3,
            turboThreshold: 10000,
          },
        },
        series: [
          {
            name: "Values",
            data: JSON.parse(JSON.stringify(seriesData)),
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });
  const checkGroupStatus = computed(() => {
    let options = [];
    if (parameterList.value.response_status == "error") {
      options = [parameterList.value.response_status];
    } else {
      options = [
        parameterList.value.response_status,
        parameterTrendOptions.value.response_status,
        alarmList.value.response_status,
      ];
    }
    return "success"
    return GetGroupStatus(options);
  });
  return {
    alarmList,
    parameterTrendOptions,
    parameterList,
    checkGroupStatus,
  };
}
