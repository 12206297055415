<template>
  <div id="controlchart">
    <b-card>
      <highcharts
        :constructor-type="'chart'"
        style="margin-left: clamp(2px, 2vw, 5px); padding-left: 0px"
        :options="options"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import gradientColors from "@/assets/widget/highchartGradientColorPalettes.json";
export default {
  props: {
    data: null,
  },
  components: {
    Highcharts: Chart,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      options: {
        colors: [
          gradientColors.blue,
          gradientColors.green,
          gradientColors.orange,
          gradientColors.purple,
          gradientColors.turquoise,
          gradientColors.yellow,
          gradientColors.olivedrab,
          gradientColors.red,
          gradientColors.olivedrab,
        ],
        credits: {
          enabled: false,
        },
        scrollbar: { enabled: false },
        rangeSelector: {
          enabled: false,
          selected: 4,
          inputEnabled: false,
          buttonTheme: {
            visibility: "hidden",
          },
          allButtonsEnabled: false,
          labelStyle: {
            visibility: "hidden",
          },
        },
        navigator: {
          visible: false,
          enabled: false,
        },
        chart: {
          height: 466,
          type: "pie",
        },
        yAxis: [
          {
            title: "",
            min: 0,
          },
        ],
        legend: {
          enabled: true,
          shadow: false,
        },
        tooltip: {
          shared: true,
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            borderRadius: 5,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
              distance: -15,
              filter: {
                property: "percentage",
                operator: ">",
                value: 8,
              },
            },
          },
        },
        series: [
          {
            name: "Share",
            data: [
              { name: "TPM Plan", y: 15 },
              { name: "Alarm!! Block Fault", y: 50 },
              { name: "Qlt Problem", y: 20 },
              { name: "MAC BRK", y: 5 },
            ],
          },
        ],
        ...this.data,
      },
    };
  },
  methods: {
    increaseValue() {
      this.options.series[0].data[3] += 10;
      let updatedOptions = JSON.parse(JSON.stringify(this.options));
      this.options.series = updatedOptions.series;
    },
  },
};
</script>
<style scoped lang="scss">
@import "./assets/styling/charts.scss";
</style>
