<template>
  <div>
    <b-row v-if="isDataLoaded">
      <b-col :lg="calculateSummaryLgSize.product" cols="12">
        <Group groupName="GroupProduct"></Group>
      </b-col>
      <b-col :lg="calculateSummaryLgSize.widget" cols="12">
        <Group groupName="GroupWidgetWorkcenter"></Group>
      </b-col>

      <b-col v-if="childWorkcenters.length > 0" cols="12">
        <Group groupName="GroupWorkcenterChild"></Group>
      </b-col>
      <b-col cols="12">
        <Group groupName="GroupChartWorkcenter"></Group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Group from "../components/groups/Group.vue";
import { BRow, BCol } from "bootstrap-vue";
import { computed } from "@vue/composition-api";
import store from "@/store";
import { loadInfo } from "../components/composables/useProductionData";
export default {
  components: {
    Group,
    BRow,
    BCol,
  },
  setup() {
    const { isDataLoaded } = loadInfo();
    const childWorkcenters = computed(() => {
      return (
        store.getters["pamis-workcenter-module/GetWorkcenterChildInfo"]
          ?.datasource ?? 0
      );
    });
    const dateFilterId = computed(
      () => store.getters["pamis-workcenter-module/GetDateFilterId"]
    );

    const calculateSummaryLgSize = computed(() => {
      if (dateFilterId.value == 2 || dateFilterId.value == 10) {
        return { product: 5, widget: 7 };
      } else {
        return { product: 3, widget: 9 };
      }
    });

    return {
      calculateSummaryLgSize,
      isDataLoaded,
      childWorkcenters,
    };
  },
};
</script>
