<template>
  <div id="controlchart">
    <b-card>
      <b-row class="chart-bar">
        <b-col>
          <b-form-select id="topdefect-selection" @change="onSelectionChange" style="width: max-content; float: right"
            v-model="selectedNum" :options="selectOptions" />
        </b-col>
      </b-row>
      <highcharts :constructor-type="'chart'" style="margin-left: clamp(2px, 2vw, 5px); padding-left: 0px"
        :options="{ ...options, title: title, series: [{ ...options.series[0], data: filteredSeries }] }"></highcharts>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BFormSelect } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import gradientColors from "@/assets/widget/highchartGradientColorPalettes.json";
import exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import i18n from "@/libs/i18n";
import {
  ref,
  inject,
  computed,
  toRefs,
} from "@vue/composition-api";
exporting(Highcharts);

const selectedNum = ref(5);
const id = ref(-1);

export default {
  props: {
    data: null,
  },
  components: {
    Highcharts: Chart,
    BCard,
    BRow,
    BCol,
    BFormSelect,
  },
  setup(props) {
    const { data } = toRefs(props);

    const selectOptions = computed(()=>{return [
      { text: `${i18n.t('Top')} 5`, value: 5 },
      { text: `${i18n.t('Top')} 10`, value: 10 },
      { text: `${i18n.t('Top')} 15`, value: 15 },
    ]});
    const wc = inject("workcenter");
    //on workcenter id change reset to default top 5.
    if (wc.id != id.value) {
      id.value = wc.id;
      selectedNum.value = 5;
    }
    /*
    const selectedDateFilterId = computed(() => {
      return store.getters["pamis-workcenter-module/GetDateFilterId"];
    });
    */
    const title = computed(() => {
      return { text: `${i18n.t('Top')} ${selectedNum.value} ${i18n.t('Defects')}` };
    });
    const filteredSeries = computed(() => {
      return data.value.series[0].data.slice(0, selectedNum.value)
    })
    const options = ref({
      colors: [
        gradientColors.blue,
        gradientColors.green,
        gradientColors.orange,
        gradientColors.purple,
        gradientColors.turquoise,
        gradientColors.yellow,
        gradientColors.olivedrab,
        gradientColors.red,
        gradientColors.olivedrab,
      ],
      credits: {
        enabled: false,
      },
      scrollbar: { enabled: false },
      rangeSelector: {
        enabled: false,
        selected: 4,
        inputEnabled: false,
        buttonTheme: {
          visibility: "hidden",
        },
        allButtonsEnabled: false,
        labelStyle: {
          visibility: "hidden",
        },
      },
      navigator: {
        visible: false,
        enabled: false,
      },
      chart: {
        type: "column",
      },
      yAxis: [
        {
          title: "",
          min: 0,
        },
      ],
      legend: {
        enabled: false,
        shadow: false,
      },
      tooltip: {
        shared: true,
      },
      yAxis: {
        opposite: false,
        show: false,
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      navigation: {
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 20,
        },
      },

      xAxis: {
        type: "category",
        categories: ["Brazil", "Turkey", "USA", "China"],
      },
      series: [
        {
          name: "Total Quantity",
          color: "rgba(165,170,255,1)",
          data: [150, 73, 20, 120],
        },
        {
          name: "NOKQuantity",
          color: "rgba(90,86,134,1)",
          data: [140, 90, 40, 10],
        },
      ],
      ...data.value,
    });
    function onSelectionChange(ctx) {
      /*
      store.dispatch("pamis-workcenter-module/GetTopDefects", {
        workcenterId: id,
        defectCount: selectedNum.value,
        dateFilterId: selectedDateFilterId.value,
      });
      */
    }
    /*
    watch(selectedDateFilterId, () => {
      store.dispatch("pamis-workcenter-module/GetTopDefects", {
        workcenterId: id,
        defectCount: selectedNum.value,
        dateFilterId: selectedDateFilterId.value,
      });
    });
    */

    return { onSelectionChange, selectedNum, selectOptions, options, title, filteredSeries };
  },
};
</script>
<style scoped>
.chart-bar {
  position: absolute;
  z-index: 4;
  left: clamp(0px, 1vw, 30px);
}
</style>
<style scoped lang="scss">
@import "./assets/styling/charts.scss";
</style>
