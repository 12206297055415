<template>
  <div v-if="widgetData != null">
    <!---->
    <LoadingScreen v-show="widgetData.response_status === 'pending'" />
    <ErrorNoData
      v-show="widgetData.response_status === 'error'"
      :context="widgetData.context"
    />
    <WarningNoData
      v-show="widgetData.response_status === 'no_data'"
      :context="widgetData.context"
    />
    <b-row v-show="false">
      <b-col>
        <div class="editor-bar">
          <feather-icon
            icon="MoveIcon"
            style="
              color: rgba(170, 170, 170, 0.5);
              margin: 5px;
              margin-right: 10px;
              float: right;
            "
            width="15px"
            height="15px"
          />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="widgetData.widgetType">
      <b-col>
        <component
         :key="JSON.stringify(this.$i18n.locale)"
          :is="widgetData.widgetType"
          :data="widgetData"
          class="widget-base"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
//shared widgets
import HighchartColumn from "@/views/pamis/components/widgets/chart-widgets/HighchartColumn.vue";
import NumericTarget from "./numeric-widgets/NumericTarget.vue";
import NumericNormal from "./numeric-widgets/NumericNormal.vue";
//workcenter widgets
import ApexChartTimeline from "./chart-widgets/ApexChartTimeline.vue";
import ApexChartPie from "./chart-widgets/ApexChartPie.vue";
import HighchartLine from "./chart-widgets/HighchartLine.vue";
import HighchartPie from "./chart-widgets/HighchartPie.vue";
import HighchartSparklineShiftProduct from "./chart-widgets/HighchartSparklineShiftProduct.vue";
import NumericProduct from "./numeric-widgets/NumericProduct.vue";
import NumericDevice from "./numeric-widgets/NumericDevice.vue";
import NumericStatus from "./numeric-widgets/NumericStatus.vue";
import TableBootstrap from "./list-widgets/TableBootstrap.vue";
import TableAlarms from "./list-widgets/TableAlarms.vue";
import ListNormal from "./list-widgets/ListNormal.vue";
import TableParameter from "./list-widgets/TableParameter.vue";
import HighchartTopDefects from "./chart-widgets/HighchartTopDefects.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import WarningNoData from "@/views/miscellaneous/WarningNoData.vue";
import ApexChartOEE from "./chart-widgets/ApexChartOEE.vue";

export default {
  props: {
    widgetData: null,
  },
  components: {
    ApexChartTimeline,
    ApexChartPie,
    HighchartColumn,
    HighchartLine,
    HighchartPie,
    HighchartSparklineShiftProduct,
    NumericNormal,
    NumericProduct,
    NumericTarget,
    NumericDevice,
    TableBootstrap,
    NumericStatus,
    TableAlarms,
    ListNormal,
    TableParameter,
    HighchartTopDefects,
    ErrorNoData,
    LoadingScreen,
    WarningNoData,
    ApexChartOEE,
    BRow,
    BCol,
  },
};
</script>
<style scoped>
.editor-bar {
  bottom: 5px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1px 2s.6px !important;
}

.widget-base {
  box-shadow: rgba(0, 0, 0, 0.075) 6.95px 6.95px 7.6px;
}
</style>
