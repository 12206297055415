import { computed } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import i18n from '@/libs/i18n'
export default function getFilterGroup() {
  const workcenterShiftsOptions = computed(() => {
    const data =
      store.getters["pamis-workcenter-module/GetShiftsProductionQuantity"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      let seriesData = data.datasource
        .filter((e) => e.name != "Total")
        .map((e) => {
          return {
            name: e.name,
            y: e.value,
          };
        });
      let categories = seriesData.map((e) => e.name);
      let total = data.datasource.filter((e) => e.name == "Total")[0];
      let options = {
        response_status: "success",
        header: i18n.t('Production Quantity'),
        value: total?.value ?? 0,
        widgetType: "HighchartSparklineShiftProduct",
        yAxis: {
          visible: true,
          labels: {
            enabled: false,
          },
          title: {
            text: i18n.t('Production'),
            enabled: true,
          },
        },
        xAxis: {
          lineWidth: 0,
          categories: categories,
        },
        exporting: {
          enabled: false,
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            dataLabels: {
              y: 20,
              enabled: true,
            },
          },
        },
        chart: {
          height: 150,
          type: "column",
        },
        series: [
          {
            name: "",
            data: seriesData,
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });

  const machineProperties = computed(() => {
    const data = store.getters["pamis-workcenter-module/GetWcStatus"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      const list = data.datasource.map((x) => {
        return {
          header: x.name,
          value: x.value,
          color:
            x.name.toLowerCase() === "status"
              ? x.value.toLowerCase() === "working"
                ? "green"
                : "red"
              : "black",
        };
      });
      const options = {
        response_status: "success",
        widgetType: "ListNormal",
        itemList: list
      };
      return options;
    }
    return dataState;
  });
  const checkGroupStatus = computed(() => {
    const options = [
      machineProperties.value.response_status,
      workcenterShiftsOptions.value.response_status,
    ];
    return GetGroupStatus(options);
  });

  return {
    machineProperties,
    workcenterShiftsOptions,
    checkGroupStatus,
  };
}
