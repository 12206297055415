<template>
  <div id="controlchart">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col>
            <apexchart height="250" :options="options.chartOptions" :series="options.series"></apexchart>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="target-cell">
            <b-row>
              <b-col>
                <h5 class="header-text ">Target</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="value-text">80%</h4>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="availability-cell">
            <b-row>
              <b-col>
                <h5 class="header-text ">Availability</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="value-text ">70%</h4>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="performance-cell">
            <b-row>
              <b-col>
                <h5 class="header-text">Performance</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="value-text">90%</h4>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="quality-cell">
            <b-row>
              <b-col>
                <h5 class="header-text ">Quality</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="value-text">75%</h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable */
import VueApexCharts from "vue-apexcharts";
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
} from "bootstrap-vue";
import { toRefs, ref } from "@vue/composition-api";
export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    data: {
      oee: 80,
      oeeTarget: 80,
      performance: 90,
      performanceTarget: 80,
      availability: 80,
      availabilityTarget: 80,
      quality: 60,
      qualityTarget: 75,
    },
  },
  setup(props) {
    const { data } = toRefs(props);
    const options = ref({
      series: [70, 90, 75],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 100,
              size: '48%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "18px",
                offsetY: -10,
              },
              value: {
                fontSize: "22px",
                offsetY: 10,
              },
              total: {
                show: true,
                label: "OEE",
                fontSize: '18px',
                fontWeight: '550',
                color: '#5e5873',

                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return `80%`;
                },
              },
            },
          },
        },
        labels: ["Availability", "Performance", "Quality",],
      },
    });
    return {
      options,
    };
  },
};
</script>
<style scoped>
.header-text {
  text-align: center;
  align-content: center;
}

.value-text {
  text-align: center;
  align-content: center;
  font-weight: 500;
}

.target-cell {
  border-right: 2px solid rgba(122, 122, 122, 0.1);
}

.availability-cell * {
  color: orange;

}

.performance-cell * {
  color: rgba(0, 227, 150, 1.00);
}

.quality-cell * {
  color: rgb(0, 132, 255);
}

.card-body {
  margin: 0px;
  padding-top: 0px;
}
</style>
