<template>
  <div>
    <b-card class="numeric-widget-normal py-25">
      <b-row
        style="margin: 0px; padding: 0px"
        :style="`border-color:${data.color}`"
      >
        <!--
        <b-col cols="2">
          <feather-icon
            icon="BarChartIcon"
            :style="`color:${data.color};margin-top:10px`"
            width="1.8vw"
            height="1.8vw"
          />
        </b-col>
        -->
        <b-col cols="12" style="text-align: center; padding: 0px">
          <h4
            class="text-muted m-b-10 header-text"
            :style="`margin-bottom:10px;color:${data.color}!important`"
          >
            {{ $t(data.header) }}
          </h4>
          <h2 class="m-b-0 f-w-600 value-text" :id="`${data.header}_value`">
            {{ count }}
            <span v-if="data.header == 'Breakdown'"> min</span>
            <span v-if="data.header != 'Breakdown'"> pcs</span>
          </h2>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import anime from "animejs";
import { ref, toRefs, watch } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    data: null,
  },
  setup(props) {
    const { data } = toRefs(props);
    const count = ref(0);
    setCount(data.value.value);
    watch(data, (newData) => {
      setCount(newData.value);
    });
    function setCount(val) {
      const obj = { n: count.value };
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 1000,
        easing: "linear",
        update: () => {
          count.value = obj.n;
        },
      });
    }
    return {
      count,
    };
  },
};
</script>
<style src="./assets/scss/widget-colors.scss" scoped lang="scss"></style>
<style scoped>
.header-text {
  width: 100%;
  padding: 0;
  font-size: clamp(0.8em, 2vw, 1.1em);
}
.value-text {
  font-size: clamp(1em, 2.2vw, 1.25em);
}
</style>
