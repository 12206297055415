<template>
  <div>
    <b-row v-show="false">
      <b-col>
        <div class="editor-bar">
          <feather-icon icon="MoveIcon" style="
              color: rgba(170, 170, 170, 0.5);
              margin: 5px;
              margin-right: 10px;
              float: right;
            " width="1.0vw" height="1.0vw" />
        </div>
      </b-col>
    </b-row>
    <div>
      <div>
        <component :is="groupName" @GroupStatusChange="OnStatusChange">
          <b-row>
            <b-col>
              <WarningNoData v-show="dataStatus == 'no_data'" />
              <ErrorNoData v-show="dataStatus == 'error'" />
              <LoadingScreen v-show="dataStatus == 'pending'" />
            </b-col>
          </b-row>
        </component>
      </div>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import GroupAlarms from "./GroupParameter.vue";
import GroupBreakdown from "./GroupBreakdown.vue";
import GroupChartWorkcenter from "./GroupChartWorkcenter.vue";
import GroupProduct from "./GroupProduct.vue";
import GroupParameter from "./GroupParameter.vue";
import GroupWidgetWorkcenter from "./GroupWidgetWorkcenter.vue";
import GroupWorkcenterChild from "./GroupWorkcenterChild.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import WarningNoData from "@/views/miscellaneous/WarningNoData.vue";
export default {
  props: {
    groupName: null,
  },
  components: {
    GroupAlarms,
    GroupBreakdown,
    GroupParameter,
    GroupChartWorkcenter,
    GroupProduct,
    GroupWidgetWorkcenter,
    GroupWorkcenterChild,
    BRow,
    BCol,
    BCard,
    LoadingScreen,
    ErrorNoData,
    WarningNoData,
  },
  setup() {
    const dataStatus = ref("");
    function OnStatusChange(context) {
      dataStatus.value = context;
    }
    return {
      dataStatus,
      OnStatusChange,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.group-card {}

.editor-bar {
  bottom: 5px;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.1);
}
</style>
