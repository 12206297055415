<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-card-title>
            {{ data.header }}
          </b-card-title>
        </b-col>
        <b-col>
          <vue-excel-xlsx
            :data="data.items"
            :columns="excelFields"
            :file-name="data.widgetType + '_' + new Date()"
            :file-type="'xlsx'"
            :sheet-name="'Page1'"
            class="btn btn-primary"
            style="float: right; margin-bottom: 10px"
          >
          {{ $t('Export to Excel') }}
          </vue-excel-xlsx></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <b-table
            stickyColumn
            responsive
            :items="data.items"
            :tbody-tr-class="rowClass"
            show-empty
            :per-page="perPage"
            empty-text="No matching records found"
            :currentPage="currentPage"
            :fields="data.fields"
          >
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
  BTable,
  BPagination,
} from "bootstrap-vue";
import anime from "animejs";
import { ref, toRefs, watch, computed } from "@vue/composition-api";
import i18n from "@/libs/i18n";
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  props: {
    data: null,
  },
  setup(props) {
    const { data } = toRefs(props);
    const currentPage = ref(1);
    let excelFields = [];
    const totalRows = computed(() => {
      if (data.value.items) {
        return data.value.items.length;
      } else return 0;
    });
    if (data.value.fields) {
      excelFields = data.value.fields.map((item) => ({
        label: item.key.toUpperCase(),
        field: item.key,
      }));
    }
    const perPage = ref(10);
    function rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.current) return;
      if (item.current > item.max || item.current < item.min)
        return "table-danger";
    }
    return {
      totalRows,
      currentPage,
      perPage,
      rowClass,
      excelFields,
    };
  },
};
</script>
<style scoped>
.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}
.click-table:hover {
  cursor: pointer;
}
</style>
