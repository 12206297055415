<template>
  <div>
    <b-card>
      <b-card-title>
        {{ data.header }}
      </b-card-title>
      <b-row> </b-row>
      <b-row v-if="data.items != null">
        <b-col cols="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="data.items.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
        <b-col lg="2" class="my-1">
          <vue-excel-xlsx
            :data="data.items"
            :columns="excelFields"
            :file-name="data.widgetType + '_' + new Date()"
            :file-type="'xlsx'"
            :sheet-name="'Page1'"
            class="btn btn-primary"
            style="padding: 10px; float: right"
          >
          {{ $t('Export to Excel') }}
          </vue-excel-xlsx>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group style="width: 100%" class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('SearchPlaceholder')"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >{{ $t('Clear') }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            hover
            selectable
            select-mode="single"
            class="click-table"
            stickyColumn
            responsive
            :filter="filter"
            @filtered="onFiltered"
            :items="data.items"
            :fields="data.fields"
            :tbody-tr-class="rowClass"
            show-empty
            no-local-sorting
            :per-page="perPage"
            empty-text="No matching records found"
            :currentPage="currentPage"
            @row-clicked="rowClickHandler"
          >
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BTable,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { ref, toRefs, computed, onMounted, watch } from "@vue/composition-api";
import useDateRange from "@/views/pamis/workcenter/components/composables/useDateRange";
import store from "@/store";

export default {
  components: {
    BPagination,
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
  },
  props: {
    data: null,
  },
  setup(props) {
    const { data } = toRefs(props);
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalRows = ref(0);
    const filter = ref(null);
    const selectedRow = ref(null);
    let excelFields = [];
    const selectedDateRange = computed(() => {
      const dateFilterId =
        store.getters["pamis-workcenter-module/GetDateFilterId"];
      const dateFilters =
        store.getters["pamis-workcenter-module/GetDateFiltersWithDates"];
      const dateFilterWithRange = dateFilters.find(
        (x) => x.value == dateFilterId
      );
      return {
        startDate: dateFilterWithRange.startDate,
        endDate: dateFilterWithRange.endDate,
      };
    });
    if (data.value.items[0]) {
      const initialSelectedRow = data.value.items[0];

      store.dispatch(
        "pamis-workcenter-module/SetSelectedParameter",
        initialSelectedRow
      );
      store.dispatch("pamis-workcenter-module/GetTrendsOfParameter", {
        ParameterId: data.value.items[0].id,
        ParameterName: data.value.items[0].tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
      store.dispatch("pamis-workcenter-module/GetAlarmsInfo", {
        parameterId: data.value.items[0].id,
        ParameterName: data.value.items[0].tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
    }

    onMounted(() => {
      if (data.value.items) totalRows.value = data.value.items.length;
      else totalRows.value = 0;
    });

    if (data.value.fields) {
      excelFields = data.value.fields.map((item) => ({
        label: item.key.toUpperCase(),
        field: item.key,
      }));
    }

    function rowClass(item, type) {
      try {
        if (!item || type !== "row") return;
        if (!item.current || item?.current == "") return;
        if (!isInRange(item)) {
          return "table-danger";
        }
      } catch (ex) {}
      function isInRange(item) {
        if (
          Number(item.current) > Number(item.max) ||
          Number(item.current) < Number(item.min)
        ) {
          return false;
        } else return true;
      }
    }

    const onFiltered = (filteredItems) => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length;
      currentPage.value = 1;
    };
    function rowClickHandler(context) {
      selectedRow.value = context;
      store.dispatch("pamis-workcenter-module/RESET_PARAMETER_DATA");
      store.dispatch("pamis-workcenter-module/SetSelectedParameter", context);
      store.dispatch("pamis-workcenter-module/GetTrendsOfParameter", {
        ParameterId: context.id,
        ParameterName: context.tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
      store.dispatch("pamis-workcenter-module/GetAlarmsInfo", {
        parameterId: context.id,
        ParameterName: context.tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
      return;
    }
    watch(selectedDateRange, (newDate, oldDate) => {
      store.dispatch("pamis-workcenter-module/GetTrendsOfParameter", {
        ParameterId: selectedRow.value.id,
        ParameterName: selectedRow.value.tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
      store.dispatch("pamis-workcenter-module/GetAlarmsInfo", {
        parameterId: selectedRow.value.id,
        ParameterName: selectedRow.value.tag,
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
      });
    });
    return {
      onFiltered,
      perPage,
      filter,
      currentPage,
      rowClickHandler,
      rowClass,
      excelFields,
    };
  },
};
</script>
<style scoped>
.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}
.click-table:hover {
  cursor: pointer;
}
</style>
