<template>
  <div>
    <slot></slot>
    <b-card>
      <b-row v-if="checkGroupStatus==='success'">
        <!-- cols will be dynamic based on chart data size-->
        <b-col lg="12" cols="12">
          <Widget
            :widgetData="parameterList"
            :key="JSON.stringify(parameterList)"
          ></Widget>
        </b-col>
      </b-row>
    </b-card>
    <b-card  v-if="parameterList.response_status =='success'">
      <b-row style="margin-bottom:10px">
        <b-col>
          <portal-target name="GroupParameter">
        </portal-target>
        </b-col>
      </b-row>
     
      <b-row>
        <b-col lg="12" cols="12">
          <Widget
            :widgetData="parameterTrendOptions"
            :key="JSON.stringify(parameterTrendOptions)"
          ></Widget>
        </b-col>
        <b-col lg="12" cols="12">
          <Widget
            :widgetData="alarmList"
            :key="JSON.stringify(parameterTrendOptions)"
          ></Widget>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import Widget from "../widgets/Widget.vue";
import { BRow, BCol, BCard } from "bootstrap-vue";
import { ref, inject, computed, watch } from "@vue/composition-api";
import useGroupParameter from "@/views/pamis/workcenter/components/composables/GroupParameter";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import DateRangeFilter from "../widgets/DateRangeFilter.vue";
import store from "@/store";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    BCard,
    Widget,
    ErrorNoData,
    ErrorNoData,
    DateRangeFilter,
    LoadingScreen,
    DateRangeFilter,
  },
  emits: ["hasData"],
  setup(props, { emit }) {
    const {
      alarmList,
      parameterList,
      parameterTrendOptions,
      checkGroupStatus,
    } = useGroupParameter();
    const wcStatus = computed(() => {
      const data =
        store.getters["pamis-workcenter-module/GetWcStatus"]?.datasource;
      if (data) return data;
      return null;
    });
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });
    return {
      wcStatus,
      parameterTrendOptions,
      alarmList,
      parameterList,
      checkGroupStatus,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style>
.trend-header {
  margin-bottom: 15px;
  margin-top: 10px;
}
</style>
