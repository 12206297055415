import {
  computed,
  ref,
  watch,
  onBeforeUnmount,
} from "@vue/composition-api";
import store from "@/store";
export function loadInfo() {
  const interval = ref(null);
  const datefilterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetDateFilterId"];
  });
  const workcenterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetWorkcenterId"];
  });
  const dateFilter = computed(() => {
    const dateFilters =
      store.getters["pamis-workcenter-module/GetDateFiltersWithDates"];
    return dateFilters.filter(x => x.value === datefilterId.value)[0];
  });
  const workcenterName = computed(() => {
    return window.location.pathname.split("/")[2];
  });
  const isDataLoaded = ref(false);

  async function loadData() {
    await Promise.allSettled([
      store.dispatch("pamis-workcenter-module/GetWorkcenterData", {
        workcenterId: workcenterId.value,
        dateFilterId: datefilterId.value,
      }),
      store.dispatch("pamis-workcenter-module/GetMESBreakdownInfo", {
        machineName: workcenterName.value,
        startDate: dateFilter.value.startDate,
        endDate: dateFilter.value.endDate,
      }),
      // store.dispatch("pamis-workcenter-module/GetWorkcenterDowntimeChart", {
      //   workcenterId: workcenterId.value,
      //   dateFilterId: datefilterId.value,
      // })
    ]);
    isDataLoaded.value = true;
  }
  /*
  async function loadShiftProduction() {
    if (
      datefilterId.value == 10 ||
      datefilterId.value == 2 ||
      datefilterId.value == 1
    ) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("pamis-workcenter-module/GetShiftsProductionQuantity", {
            workcenterId: workcenterId.value,
            dateFilterId: datefilterId.value,
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  */
  function refreshData() {
    loadData();
    interval.value = setInterval(() => {
      loadData();
    }, 300000);
  }

  refreshData();

  watch(datefilterId, () => {
    loadData();
  });

  onBeforeUnmount(() => {
    clearInterval(interval.value);
  });
  return { isDataLoaded };

}
export function loadParameter() {

  const interval = ref(null);

  const datefilterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetDateFilterId"];
  });

  const workcenterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetWorkcenterId"];
  });

  refreshParameterData();

  async function refreshParameterData() {
    await Promise.allSettled([
      store.dispatch("pamis-workcenter-module/GetParametersInfo", {
        workcenterId: workcenterId.value ?? 0,
      }),
    ]);
  }

  interval.value = setInterval(() => {
    refreshParameterData();
  }, 60000);

  onBeforeUnmount(() => {
    clearInterval(interval.value);
  });

  watch([datefilterId], () => {
    //refreshData();
  });
  //refreshData();
}
export function loadDowntimes() {
  const interval = ref(null);

  const datefilterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetDateFilterId"];
  });
  const workcenterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetWorkcenterId"];
  });
  const workcenterName = computed(() => {
    return window.location.pathname.split("/")[2];
  });
  const dateFilter = computed(() => {
    const dateFilters =
      store.getters["pamis-workcenter-module/GetDateFiltersWithDates"];
    return dateFilters.filter(x => x.value === datefilterId.value)[0];
  });

  async function refreshData() {
    await Promise.allSettled([
      store.dispatch("pamis-workcenter-module/GetMESBreakdownInfo", {
        machineName: workcenterName.value,
        startDate: dateFilter.value.startDate,
        endDate: dateFilter.value.endDate,
      }),
      store.dispatch("pamis-workcenter-module/GetBreakdownActualInfo", {
        workcenterId: workcenterId.value,
        dateFilterId: datefilterId.value,
      }),
      store.dispatch("pamis-workcenter-module/GetBreakdownRate", {
        workcenterId: workcenterId.value,
        dateFilterId: datefilterId.value,
      }),
    ]);
  }

  interval.value = setInterval(() => {
    refreshData();
  }, 300000);

  onBeforeUnmount(() => {
    clearInterval(interval.value);
  });

  watch([datefilterId], () => {
    refreshData();
  });
  refreshData();
}
