import { computed } from "@vue/composition-api";
import store from "@/store";
import chartColorPallete from "@/assets/widget/highchartGradientColorPalettes.json";
import { GetGroupStatus, GetDataStatus } from "@/utils/utils.js";
import Highcharts from "highcharts";
import i18n from '@/libs/i18n'

//import wcProductData from "@/views/pamis/workcenter/dummy-db/wcProductData.json";
export default function getPlantLineWidgetGroup() {
  // if data is null then the response is pending.
  // if data is not null but there is no data then query returned empty,
  // if data is not null and there is data then its success
  // if it gives an error it should display an error message
  // if there is no pending state left in the group then it should eiher resolve no data or render the group
  // if all of the group results are error or empty, the error component should be displayed.
  // if any of the results returns success, then generate the group.
  const datefilterId = computed(() => {
    return store.getters["pamis-workcenter-module/GetDateFilterId"];
  });
  const ConvertXAxisDateColumnChart = function (seriesData) {
    //parameter format must be [{name(CategoryName),x(Date),y(Value)},]
    let chartSeries = [];
    let categories = [];
    for (let i in seriesData) {
      if (!categories.includes(seriesData[i].name)) {
        categories.push(seriesData[i].name);
      }
    }
    for (let c in categories) {
      let categoryDataArr = [];
      let chartSeriesObject = {};
      let chartDataValues = [];
      categoryDataArr = seriesData.filter(x => x.name == categories[c]);
      chartDataValues = categoryDataArr.map(key => {
        let date = new Date(key.x);
        return {
          x: date.getTime() - date.getTimezoneOffset() * 60 * 1000,
          y: key.y,
        };
      });
      chartDataValues.sort((a, b) => {
        return b.x - a.x;
      });
      chartSeriesObject = { name: categories[c], data: chartDataValues };
      chartSeries.push(chartSeriesObject);
    }
    return chartSeries;
  };

  const productionQuantityOptions = computed(() => {
    let data = //wcProductData
      store.getters["pamis-workcenter-module/GetWorkcenterProductionData"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let chartSeries = [];
      let maxValue = 0;
      let minValue = 0;
      let seriesData = data.datasource.map(key => {
        maxValue = key.value > maxValue ? key.value : maxValue;
        minValue = minValue > key.value ? key.value : minValue;
        return {
          name: key.name,
          x: key.dateTime,
          y: key.value,
        };
      });
      chartSeries = ConvertXAxisDateColumnChart(seriesData);
      let options = {
        response_status: "success",
        widgetType: "HighchartLine",
        plotOptions: {
          series: {
            lineWidth: 3,
          },
        },
        title: {
          text: i18n.t("Production Quantities"),
        },
        xAxis: {
          showLastLabel: true,
          endOnTick: true,
          labels: {},
          type: "datetime",
        },
        yAxis: {
          min: minValue > data.kpiEfficiency ? data.kpiEfficiency : minValue,
          max:
            maxValue > data.kpiProductionQuantity
              ? maxValue * 1.1
              : data.kpiProductionQuantity * 1.1,
          title: { text: i18n.t("Quantity")},
          plotLines: [
            {
              label: {
                y: -8,
                x: -8,
                text: `Target: ${data.kpiProductionQuantity}`,
                style: {
                  color: "red",
                },
              },
              color: "#FF0000",
              value: data.kpiProductionQuantity,
              width: 2,
              dashStyle: "LongDashDot",
              zIndex: 4,
            },
          ],
        },
        series: chartSeries,
      };
      return options;
    } else {
      return dataState;
    }
  });

  const topDefectsOptions = computed(() => {
    let data = store.getters["pamis-workcenter-module/GetTopDefects"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let dataSeries = data.datasource.map(x => {
        return [x.name, x.value];
      });
      let options = {
        response_status: "success",
        widgetType: "HighchartTopDefects",
        xAxis: {
          type: "category",
          categories: data.datasource.map(x => x.name),
        },
        series: [
          {
            type: "bar",
            //name: "PPM",
            colors: [
              "#006080",
              "#007399",
              "#0086b3",
              "#0099cc",
              "#00ace6",
              "#00bfff",
              "#1ac6ff",
              "#33ccff",
              "#4dd2ff",
              "#66d9ff",
              "#80dfff",
              "#99e6ff",
              "#b3ecff",
              "#ccf2ff",
              "#e6f9ff",
            ],
            colorByPoint: true,
            data: dataSeries,
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });

  const productionPPMOptions = computed(() => {
    let data =
      store.getters["pamis-workcenter-module/GetWorkcenterProductionData"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let maxValue = 0;
      let minValue = 0;
      let chartSeries = [];
      let seriesData = data.datasource.map(key => {
        maxValue = key.ppm > maxValue ? key.ppm : maxValue;
        minValue = minValue > key.value ? key.value : minValue;
        return {
          name: key.name,
          x: key.dateTime,
          y: key.ppm,
        };
      });
      chartSeries = ConvertXAxisDateColumnChart(seriesData);
      let options = {
        response_status: "success",
        widgetType: "HighchartLine",
        plotOptions: {
          series: {
            lineWidth: 3,
          },
        },
        title: {
          text: i18n.t("Production PPM"),
        },
        yAxis: {
          min: minValue > data.kpiEfficiency ? data.kpiEfficiency : minValue,
          max: maxValue > data.kpiPPM ? maxValue * 1.1 : data.kpiPPM * 1.1,
          title: { text: i18n.t("Million")},
          plotLines: [
            {
              label: {
                y: -8,
                x: -8,
                text: `Target: ${data.kpiPPM}`,
                style: {
                  color: "red",
                },
              },
              color: "#FF0000",
              value: data.kpiPPM,
              width: 2,
              dashStyle: "LongDashDot",
              zIndex: 4,
            },
          ],
        },
        xAxis: {
          showLastLabel: true,
          endOnTick: true,
          type: "datetime",
        },
        series: chartSeries,
      };
      return options;
    } else {
      return dataState;
    }
  });

  const productionEfficiencyOptions = computed(() => {
    let data =
      store.getters["pamis-workcenter-module/GetWorkcenterEfficiencyTrend"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let chartSeries = [];
      let maxValue = 0;
      let minValue = 0;
      let seriesData = data.datasource.map(key => {
        maxValue = key.value > maxValue ? key.value : maxValue;
        minValue = minValue > key.value ? key.value : minValue;
        return {
          name: key.name,
          x: key.dateTime,
          y: key.value,
        };
      });
      //find categories
      chartSeries = ConvertXAxisDateColumnChart(seriesData);
      let options = {
        response_status: "success",
        widgetType: "HighchartLine",
        title: {
          text:  i18n.t("Efficiency Trend"),
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: minValue > data.kpiEfficiency ? data.kpiEfficiency : minValue,
          max:
            maxValue > data.kpiEfficiency
              ? maxValue * 1.1
              : data.kpiEfficiency * 1.1,
          title: { text: "Percentile" },
          plotLines: [
            {
              label: {
                y: -8,
                x: -8,
                text: `Target: ${data.kpiEfficiency}`,
                style: {
                  color: "red",
                },
              },
              color: "#FF0000",
              value: data.kpiEfficiency,
              width: 2,
              dashStyle: "LongDashDot",
              zIndex: 4,
            },
          ],
        },
        series: chartSeries,
      };
      return options;
    } else {
      return dataState;
    }
  });
  const breakdownPieOptions = computed(() => {
    const data =
      store.getters["pamis-workcenter-module/GetWorkcenterDowntimePieChart"];

    const dataState = GetDataStatus(data);
    //HighchartPie
    if ((dataState.response_status == "success")) {
      for (let sourceData of data.datasource) {
        let startDate = new Date(sourceData.startDate)
        let calculatedStartDate = calculateStartDate(startDate, data.startDateByFilter)
        let calculatedEndDate = calculateEndDate(sourceData.endDate, data.endDateByFilter)
        sourceData.startDate = Math.round(calculatedStartDate)
        sourceData.endDate = calculatedEndDate
      }
      const categories = getPieCategories(data.datasource);
      const pieSlices = getPieSlices(data.datasource, categories);
      const workcenterData = [];
      const downtimeData = [];
      for (let i = 0; i < pieSlices.length; i++) {
        workcenterData.push({
          name: categories[i],
          y: pieSlices[i].y,
          color: pieSlices[i].color,
        });
        const drillDataLen = pieSlices[i].drilldown.data.length;
        for (let j = 0; j < drillDataLen; j += 1) {
          const name = pieSlices[i].drilldown.categories[j];
          let brightness = 0.1 - j / drillDataLen / 4
          downtimeData.push({
            name,
            y: pieSlices[i].drilldown.data[j],
            color: Highcharts.color(pieSlices[i].color)
              .brighten(brightness)
              .get(),
          });
        }
      }
      const options = {
        response_status: "success",
        widgetType: "HighchartPie",
        title: {
          text: i18n.t("Breakdown Type Analysis"),
        },
        tooltip: {
          formatter: function () {
            return '' + this.point.name + '<br/>' +
              '</b>' + 'Downtimes: ' + ' <b>' + this.percentage.toFixed(1) + '% </b>';
          },
        },
        series: [
          {
            name: "Workcenters",
            data: workcenterData,
            size: "60%",
            dataLabels: {
              color: "#ffffff",
              distance: "-40%",
            },
          },
          {
            name: "Downtimes",
            data: downtimeData,
            size: "80%",
            innerSize: "60%",
            dataLabels: {
              distance: "10%",
              format:
                '<b>{point.name}:</b> <span style="opacity: 0.8">{point.percentage:.1f} %</span>',

              filter: {
                property: "y",
                operator: ">",
                value: 1,
              },
              style: {
                fontWeight: "normal",
              },
            },
            id: "versions",
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });
  function getPieCategories(datasource) {
    const categories = [];
    datasource.forEach(e => {
      if (!categories.includes(e.workcenter)) {
        categories.push(e.workcenter);
      }
    });
    return categories;
  }
  function getPieSlices(datasource, categories) {
    let c = 0;
    let colors = [
      chartColorPallete.orange,
      chartColorPallete.darkblue,
      chartColorPallete.purple,
      chartColorPallete.yellow,
      chartColorPallete.olivedrab,
      chartColorPallete.blue,
      chartColorPallete.red,
    ];
    const pieSlices = [];
    for (let category of categories) {
      let categoryPieSlices = datasource.filter(
        e => e.workcenter == category
      );
      let yVal = categoryPieSlices.reduce(
        (accumulator, currentVal) =>
          accumulator + (currentVal.endDate - currentVal.startDate),
        0
      );
      let distinctCategoryPieSlices = [];
      let distinctBreakdownCategories = [];
      categoryPieSlices.forEach(e => {
        if (!distinctBreakdownCategories.includes(e.name)) {
          distinctBreakdownCategories.push(e.name)
        }
      })
      for (let distinctBdCategory of distinctBreakdownCategories) {
        let filteredValues = categoryPieSlices.filter(e => e.name == distinctBdCategory).map(e => (e.endDate - e.startDate));
        let accumulatedValue = filteredValues.reduce((accumulator, currVal) =>
          accumulator + currVal, 0
        );
        let distinctPieSlice = { name: distinctBdCategory, value: accumulatedValue }
        distinctCategoryPieSlices.push(distinctPieSlice);

      }

      const color = colors[c];
      const drilldown = {
        name: category,
        categories: distinctCategoryPieSlices.map(e => e.name),
        data: distinctCategoryPieSlices.map(e => e.value)
      };
      pieSlices.push({ y: yVal, color: color, drilldown: drilldown });
      c++;
    }
    return pieSlices;
  }
  const breakdownTimelineOptions = computed(() => {
    const data =
      store.getters["pamis-workcenter-module/GetWorkcenterDowntimeChart"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      let date = new Date()
      const series = [];
      const downTimeCategories = [];
      data.datasource.forEach(downTime => {
        if (!downTimeCategories.includes(downTime.name)) {
          downTimeCategories.push(downTime.name);
        }
      })
      for (let downtimeName of downTimeCategories) {
        const downTimes = data.datasource.filter(e => e.name == downtimeName)
        const downTimeSeries = downTimes.map((e) => {
          let startDate = calculateStartDate(e.startDate, data.startDateByFilter) - date.getTimezoneOffset() * 60 * 1000
          let endDate = calculateEndDate(e.endDate, data.endDateByFilter) - date.getTimezoneOffset() * 60 * 1000
          return {
            x: e.workcenter,
            y: [startDate, endDate]
          }
        })
        series.push({
          name: downtimeName,
          data: downTimeSeries
        })
      }

      let options = { series: series, widgetType: "ApexChartTimeline" };
      return options;
    } else {
      return dataState;
    }
  });

  function calculateStartDate(startDate, filterStartDate) {
    let sDate = new Date(startDate);
    let filterStartDateObj = new Date(filterStartDate)
    if (filterStartDateObj.getTime() > sDate.getTime()) {
      return filterStartDateObj.getTime()
    }
    return sDate.getTime();
  }

  function calculateEndDate(endDate, filterEndDate) {
    const endDateTime = (new Date(endDate)).getTime();
    if (endDate != null)
      return endDateTime;
    let currentTypeDateFilterIds = [1, 2, 3, 4, 13]
    if (currentTypeDateFilterIds.some(e => e == datefilterId.value)) {
      return Date.now();
    }
    else {
      let date = new Date(filterEndDate)
      return date.getTime()
    }
  }


  const checkGroupStatus = computed(() => {
    const options = [
      productionQuantityOptions.value?.response_status,
      productionPPMOptions.value?.response_status,
      productionEfficiencyOptions?.value.response_status,
      topDefectsOptions.value?.response_status,
      breakdownPieOptions.value?.response_status,
      breakdownTimelineOptions.value?.response_status,
    ];

    return GetGroupStatus(options);
  });

  return {
    productionQuantityOptions,
    productionPPMOptions,
    productionEfficiencyOptions,
    topDefectsOptions,
    breakdownPieOptions,
    breakdownTimelineOptions,
    checkGroupStatus,
  };
}
