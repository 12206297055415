<template>
  <div>
    <b-card no-body>
      <b-card-body style="height: 220px;" >
        <h2 style="text-align: center; font-size: 0.7vw" class="m-b-0">
          {{ $t(data.name) }}
        </h2>
        <b-row>
          <b-col>
            <b-img
            center
            width="64px"
              :src="data.logo"
              class="img-fluid"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 style="text-align: center; font-size: 0.6vw">Shift</h5>
            <h6 style="text-align: center; font-size: 0.6vw">
              {{ data.subName }}
            </h6>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer style="padding:12px 21px 12px 21px ;">
        <b-row>
          <b-col>
            <h5
              style="text-align: center; font-size: 0.6vw"
              class="text-muted m-b-10"
            >
              {{ data.leftHeader }}
            </h5>
            <h4
              style="text-align: center; font-size: 0.7vw"
              class="m-b-0 f-w-600"
              :id="`${data.leftHeader}_value`"
            >
              {{ data.leftValue }}
            </h4>
          </b-col>
          <b-col class="col-6" style="border-left: 1px solid #ebedf3">
            <h5
              style="text-align: center; font-size: 0.6vw"
              class="text-muted m-b-10"
            >
              {{ data.rightHeader }}
            </h5>
            <h4
              style="text-align: center; font-size: 0.7vw"
              class="m-b-0 f-w-600"
              :id="`${data.rightHeader}_value`"
            >
              {{ data.rightValue }}
            </h4>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";
export default {
  components: {
    BImg,
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
  },
  props: {
    data: {
      name: null,
      subName: null,
      leftHeader: null,
      rightHeader: null,
      leftValue: null,
      rightValue: null,
      logo: null,
    },
  },
  computed: {
    getCardColor() {
      if (this.data.rightValue < 50) {
        return "red";
      } else if (this.data.rightValue <= this.data.efficiencyLimit) {
        return "orange";
      } else if (this.data.rightValue > this.data.efficiencyLimit) {
        return "green";
      }
    },
  },
};
</script>
<style>
</style>
