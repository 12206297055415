import { computed } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
export default function getPlantWidgetGroup() {
  const wcWidgetsData = computed(function () {
    let data =
      store.getters["pamis-workcenter-module/GetWorkcenterSummaryInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let colors = ["cornflowerblue", "tomato", "BlueViolet", "peru"];
      let colorIndex = -1;
      let siteData = [];
      siteData = data.datasource
        .filter((e) => e.target == null&& !(e.name == "Mttr" || e.name == "Mtbf"))
        .map((x) => {
          colorIndex++;
          return {
            widgetType: "NumericNormal",
            header: x.name,
            value: x.value,
            color: colors[colorIndex],
          };
        });
      return { response_status:"success", data: siteData };
    } else {
      return dataState;
    }
  });
  const wcTargetWidgetsData = computed(function () {
    let data =
      store.getters["pamis-workcenter-module/GetWorkcenterSummaryInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let colors = ["green", "purple", "blue", "turquoise"];
      let colorIndex = -1;
      let siteData = [];
      siteData = data.datasource
        .filter(
          (e) => e.target != null && !(e.name == "Mttr" || e.name == "Mtbf")
        )
        .map((x) => {
          colorIndex++;
          return {
            widgetType: "NumericTarget",
            header: x.name,
            value: "%" + x.value,
            targetValue: x.target,
            color: colors[colorIndex],
          };
        });
      return { response_status: "success", data: siteData };
    } else {
      return dataState;
    }
  });
  const checkGroupStatus = computed(() => {
    const options = [
      wcTargetWidgetsData.value.response_status,
      wcTargetWidgetsData.value.response_status,
    ];
    return GetGroupStatus(options);
  });
  return {
    wcWidgetsData,
    wcTargetWidgetsData,
    checkGroupStatus,
  };
}
