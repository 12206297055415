<template>
  <div>
    <h3 class="trend-header">{{ $t("Downtimes") }}</h3>
    <slot></slot>
    <b-row v-if="checkGroupStatus === 'success'">
      <b-col cols="12">
        <b-tabs>
          <b-tab :title="$t('Breakdown List')">
            <Widget
              :widgetData="mesDowntimeList"
              :key="JSON.stringify(mesDowntimeList)"
            />
          </b-tab>
          <!-- <b-tab title="Actual">
            <Widget
              :widgetData="actualDowntimeList"
              :key="JSON.stringify(actualDowntimeList)"
            />
          </b-tab> -->
        </b-tabs>
      </b-col>
      <b-col lg="6" cols="12">
        <Widget :widgetData="mttrChart" :key="JSON.stringify(mttrChart)" />
      </b-col>
      <b-col lg="6" cols="12">
        <Widget
          :widgetData="breakdownRateChart"
          :key="JSON.stringify(breakdownRateChart)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Widget from "../widgets/Widget.vue";
import useGroupBreakdown from "@/views/pamis/workcenter/components/composables/GroupBreakdown";
import { BRow, BCol, BTab, BTabs, BCard } from "bootstrap-vue";
import { ref, inject, watch } from "@vue/composition-api";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import ApexChartTimeline from "../widgets/chart-widgets/ApexChartTimeline.vue";
//dispatch plant data
export default {
  components: {
    BCard,
    BRow,
    BCol,
    Widget,
    BTabs,
    BTab,
    LoadingScreen,
    ErrorNoData,
    ErrorNoData,
  },
  emits: ["hasData"],
  setup(props, { emit }) {
    const {
      checkGroupStatus,
      mesDowntimeList,
      actualDowntimeList,
      mttrChart,
      breakdownPieOptions,
      breakdownRateChart,
    } = useGroupBreakdown();

    emit("GroupStatusChange", checkGroupStatus.value);

    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });

    return {
      mesDowntimeList,
      actualDowntimeList,
      mttrChart,
      breakdownPieOptions,
      checkGroupStatus,
      breakdownRateChart,
    };
  },
};
</script>
<style>
.trend-header {
  margin-bottom: 15px;
  margin-top: 10px;
}
</style>
