<template>
  <div>
    <h3 class="trend-header">{{ $t('Workcenter Charts') }}</h3>
    <slot></slot>

    <b-row v-if="checkGroupStatus === 'success'">
      <!-- cols will be dynamic based on chart data size-->
      <b-col cols="12" lg="6">
        <Widget
          :widgetData="productionQuantityOptions"
          :key="JSON.stringify(productionQuantityOptions)"
        />
      </b-col>
      <b-col cols="12" lg="6">
        <Widget
          :widgetData="productionPPMOptions"
          :key="JSON.stringify(productionPPMOptions)"
        />
      </b-col>
      <b-col cols="12" lg="4">
        <Widget
          :widgetData="workcenterShiftsOptions"
          :key="JSON.stringify(workcenterShiftsOptions)"
        />
      </b-col>
      <b-col cols="12" lg="12">
        <Widget
          :widgetData="productionEfficiencyOptions"
          :key="JSON.stringify(productionEfficiencyOptions)"
        />
      </b-col>
      <b-col cols="12" lg="12">
        <Widget
          :widgetData="topDefectsOptions"
          :key="JSON.stringify(topDefectsOptions)"
        />
      </b-col>
      <b-col lg="5" cols="12">
        <Widget
          :widgetData="breakdownPieOptions"
          :key="JSON.stringify(breakdownPieOptions)"
        />
      </b-col>
      <b-col lg="7" cols="12">
        <Widget
          :widgetData="breakdownTimelineOptions"
          :key="JSON.stringify(breakdownTimelineOptions)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Widget from "../widgets/Widget.vue";
import { BRow, BCol } from "bootstrap-vue";
import useGroupChartWorkcenter from "@/views/pamis/workcenter/components/composables/GroupChartWorkcenter";
import { ref, inject, computed, watch } from "@vue/composition-api";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import store from "@/store";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  emits: ["hasData"],
  setup(props, { emit }) {
    const {
      productionQuantityOptions,
      productionPPMOptions,
      productionEfficiencyOptions,
      workcenterShiftsOptions,
      topDefectsOptions,
      breakdownPieOptions,
      breakdownTimelineOptions,
      checkGroupStatus,
    } = useGroupChartWorkcenter();

    const datefilterId = computed(() => {
      return store.getters["pamis-workcenter-module/GetDateFilterId"];
    });

    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });
    
    return {
      datefilterId,
      topDefectsOptions,
      productionQuantityOptions,
      productionPPMOptions,
      productionEfficiencyOptions,
      workcenterShiftsOptions,
      breakdownPieOptions,
      checkGroupStatus,
      breakdownTimelineOptions,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style>
.trend-header {
  margin-bottom: 15px;
  margin-top: 10px;
}
</style>
