<template>
  <div>
    <b-card
      no-body
      style="padding-bottom: 8px; padding-top: 10px"
      class="card-body"
    >
      <b-row v-for="item in data.itemList" :key="item.header" class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="6" class="left-prop-column">{{
              $t(item.header)
            }}</b-col>
            <b-col
              cols="auto"
              class="right-prop-column min-col"
              :style="`color:${item.color}!important`"
              >{{ $t(item.value) }}</b-col
            >
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    data: [],
  },
  computed: {
    headerClass() {
      return {
        [`col-${2}`]: true, // Set the minimum column size
        "col-auto": true, // Allow the column to size dynamically based on content
      };
    },
    getCardColor() {
      if (this.data.rightValue < 50) {
        return "red";
      } else if (this.data.rightValue <= this.data.efficiencyLimit) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
};
</script>
<style>
.device-props {
  margin-left: auto;
  text-align: justify;
  margin-bottom: clamp(2px, 1vw, 4px);
  margin-top: clamp(2px, 1vw, 4px);
  font-size: Clamp(0.8em, 1.6vw, 1em);
  color: black;
}

.left-prop-column {
  font-weight: bold;
}

.right-prop-column {
  margin-left: 1em;
  margin-right: 0.03em;
}

.list-rows:not(:last-child) {
  border-bottom: 2px solid rgba(122, 122, 122, 0.1);
}

.device-props.row {
  align-items: center;
}
.min-col {
  min-width: calc(16.66667%);
  flex-grow: 1;
}
</style>
