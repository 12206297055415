<template>
  <div class="workcenter-child-widget-group" v-if="workcenterChildData.data.length>0">
    <b-row>
      <b-col>
        <h4 style="margin-bottom: 20px">{{ $i18n.t("Workcenters") }} </h4>
        <slot> </slot>
        <b-row>
          <b-col
            lg="2"
            md="4"
            sm="6"
            cols="6"
            v-for="data in workcenterChildData.data"
            :key="JSON.stringify(data)"
          >
            <Widget :widgetData="data" :key="JSON.stringify(data)" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import useGroupWorkcenterChild from "../composables/GroupWorkcenterChild";
import NumericWidgetGradient from "../widgets/numeric-widgets/NumericStatus.vue";
import { BRow, BCol } from "bootstrap-vue";
import { ref, inject, watch } from "@vue/composition-api";
import Widget from "../widgets/Widget.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    NumericWidgetGradient,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  setup(props, { emit }) {
    const {
      workcenterChildData,
      checkGroupStatus,
    } = useGroupWorkcenterChild(props);
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });
    return {
      workcenterChildData,
      checkGroupStatus,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.workcenter-child-widget-group {
  padding-top: 10px;
  border-top: 1px solid #ebedf3;
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
</style>
