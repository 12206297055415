<template>
  <div>
    <b-card>
      <b-card-title>
        {{ data.header }}
      </b-card-title>
      <b-row> </b-row>
      <b-row v-if="data.items != null">
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="data.items.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
        <b-col lg="2" class="my-1">
          <vue-excel-xlsx
            :data="data.items"
            :columns="excelFields"
            :file-name="'Table Alarm' + '_' + new Date()"
            :file-type="'xlsx'"
            :sheet-name="'Page1'"
            style="float:right"
            class="btn btn-primary"
          >
          {{ $t('Export to Excel') }}
          </vue-excel-xlsx>
        </b-col>
        <b-col lg="4" class="my-1">
          <b-form-group
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('SearchPlaceholder')"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >{{ $t('Clear') }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            hover
            selectable
            select-mode="single"
            class="click-table"
            stickyColumn
            responsive
            :filter="filter"
            @filtered="onFiltered"
            :items="data.items"
            :fields="data.fields"
            show-empty
            no-local-sorting
            :per-page="perPage"
            empty-text="No matching records found"
            :currentPage="currentPage"
          >
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BTable,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { ref, toRefs, watch, onMounted } from "@vue/composition-api";
import useDateRange from "@/views/pamis/workcenter/components/composables/useDateRange";
import store from "@/store";
export default {
  components: {
    BPagination,
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
  },
  props: {
    data: null,
  },
  setup(props) {
    const { data } = toRefs(props);
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalRows = ref(0);
    const filter = ref(null);
    let excelFields = [];
    onMounted(() => {
      totalRows.value = data.value.items != null ? data.value.items.length : 0;
    });
    if (data.value.fields) {
      excelFields = data.value.fields.map((item) => ({
        label: item.key.toUpperCase(),
        field: item.key,
      }));
    }
    const onFiltered = (filteredItems) => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length;
      currentPage.value = 1;
    };
    return {
      onFiltered,
      perPage,
      filter,
      currentPage,
      excelFields,
    };
  },
};
</script>
<style scoped>
.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}
.click-table:hover {
  cursor: pointer;
}
</style>
