<template>
  <div id="controlchart">
    <b-card>
      <apexchart
        type="pie"
        height="500"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable */
import VueApexCharts from "vue-apexcharts";
import { BCard } from "bootstrap-vue";
export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
  },
  data() {
    return {
      series: [44, 55, 13],
      chartOptions: {
        colors: [
          "#F44336",
          "#2196F3",
          "#4CAF50",
          "#FFEB3B",
          "#9C27B0",
          "#795548",
          "#E91E63",
          "#03A9F4",
          "#8BC34A",
          "#FFC107",
          "#673AB7",
          "#9E9E9E",
          "#FF5722",
          "#00BCD4",
          "#CDDC39",
          "#FF9800",
          "#3F51B5",
          "#607D8B",
          "#C62828",
          "#1E88E5",
          "#43A047",
          "#FDD835",
          "#AB47BC",
          "#8D6E63",
          "#EC407A",
          "#29B6F6",
          "#9CCC65",
          "#FFCA28",
          "#7E57C2",
          "#BDBDBD",
        ],
        labels: ['TPM', 'Line Fault', 'Planned'],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
         
        },
        
      },
    };
  },
};
</script>
<style></style>
