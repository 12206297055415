import { onUnmounted, watchEffect,onDeactivated } from "@vue/composition-api";
import store from "@/store";
/**
 * @param {Object} breadCrumb - The breadcrumb object.
 * @param {string} breadCrumb.text - The text of the breadcrumb.
 * @param {string} breadCrumbO.to - The destination URL of the breadcrumb.
 * @param {boolean} breadCrumb.active - A flag indicating if the breadcrumb is active.
 */
export default function useExtensionalBreadCrumb(breadCrumbObject) {
  watchEffect(() => {
    if(breadCrumbObject.value){
      // computed and ref 
      store.dispatch("app/ADD_BREAD_CRUMB", breadCrumbObject.value);
    }
    else{
      //constant 
      store.dispatch("app/ADD_BREAD_CRUMB", breadCrumbObject);
    }
   
  });

  onUnmounted(() => {
    store.dispatch("app/CLEAR_BREAD_CRUMB");
  });
}
