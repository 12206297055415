<template>
  <div>
    <b-row>
      <b-col>
        <Group groupName="GroupBreakdown" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Group from "../components/groups/Group.vue";
import draggable from "vuedraggable";
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import { inject, ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { loadDowntimes } from "../components/composables/useProductionData";
export default {
  components: {
    Group,
    BRow,
    BCol,
    draggable,
    BTab,
    BTabs,
  },
  setup() {
    loadDowntimes();
    return {};
  },
};
</script>
